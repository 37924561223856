export const colors = {
  white: {
    shade1: '#fff',
    shade2: '#f5f5f5',
    shade3: 'fdfdfd',
    shade4: '#FEFEFF',
    shade5: '#f1f1f1',
  },
  red: {
    shade1: '#e4022d',
    shade2: '#eb0029',
    shade3: '#f44545',
    shade4: '#D60303',
    shade5: '#EA5E73',
  },
  orange: {
    shade1: '#ffc000',
    shade2: '#FF6200',
    shade3: 'rgb(255, 113, 0)',
    shade4: '#FF5400',
  },
  blue: {
    shade1: '#0000ee',
    shade2: '#4d6fa9',
    shade3: '#495867',
  },
  green: {
    shade1: '#8fca00',
    shade2: '#aad500',
    shade3: '#a9d100',
    shade4: '#8dc600',
    shade5: '#71b300',
    shade6: '#a4d41e',
    shade7: '#fefff8',
    shade8: '#e3ff57',
    shade9: '#93c900',
    shade10: '#6B8E23',
    shade11: '#9BC930',
    shade12: '#8eca00',
    shade13: '#f9fcf3',
    shade14: '#8dcb00',
    shade15: '#74B700',
    shade16: '#85c00114',
    shade17: '#62ba46',
    shade18: '#b3d341',
    shade19: '#99C33C',
    shade20: '#30871F',
    shade21: '#F3F9E5',
    shade22: '#186F07',
    shade23: '#D7F0D9',
    shade24: '#DAFEE3',
    shade25: '#105726',
    shade26: '#0DA137',
  },
  black: {
    shade1: '#333',
    shade3: '#000',
    shade4: '#222',
    shade5: '#171717',
    shade6: '#666',
    shade7: '#888',
    shade8: '#3d3d3d',
    shade9: '#343433',
    shade10: '#333333',
    shade11: '#013F48',
    shade12: '#909294',
  },
  gray: {
    shade1: '#e9e9e9',
    shade2: '#e0e0e0',
    shade3: '#f8f8f8',
    shade4: '#1a1a1a',
    shade5: '#bbb',
    shade6: '#eee',
    shade7: '#ececec',
    shade8: '#7e7e7e',
    shade9: '#e5e5e5',
    shade10: '#999',
    shade11: '#303030',
    shade12: '#f9f9f9',
    shade13: '#eee',
    shade14: '#f0f0f0',
    shade15: '#333',
    shade16: '#616161',
    shade17: '#dedede',
    shade18: '#6b6b6b',
    shade19: '#888',
    shade20: '#222',
    shade21: '#2a2a2a',
    shade22: '#4c4c4c',
    shade23: '#2e2e2e',
    shade24: '#a9a9a9',
    shade25: '#bababa',
    shade26: '#f7f7f7',
    shade27: '#a9a9a9',
    shade28: '#e3e3e3',
    shade29: '#9b9b9b',
    shade30: '#908f96',
    shade31: '#cfcfcf',
    shade32: '#fafafa',
    shade33: '#cccccc',
    shade34: '#c0c0c0',
    shade35: '#d2d2d2',
    shade36: '#e6e6e6',
    shade37: '#ddd',
    shade38: '#9d9d9d',
    shade39: '#979797',
    shade40: '#4A4A4A',
    shade41: '#FBFBFB',
    shade42: '#c1c1c1',
    shade43: '#ededed',
    shade44: '#767676',
    shade45: '#495867',
    shade46: '#E1E1E1',
    shade47: '#636363',
    shade48: '#68717D',
    shade50: '#e2e2e2',
    shade51: '#fbfbfb',
    shade52: '#717171',
    shade53: '#8b8b8b',
    shade54: '#d1d1d1',
    shade55: '#A9B5C0',
    shade56: '#D9D9D9',
    shade57: '#F1F1F1',
    shade58: '#D3D7DB',
    shade59: '#EDEEF0',
    shade60: '#343E49',
    shade61: '#8c969f',
  },
  socialIcons: {
    facebook: '#5479bd',
    twitter: '#55acee',
    instagram: '#e1306c',
    youtube: '#ce1312',
    pinterest: '#bd081c',
    email: '#BBBBBB',
    whatsapp: '#4CAF50',
  },
  warning: '#ee4b4b',
  yellow: {
    shade1: '#ecdb69',
    shade2: '#ffbf05',
    shade3: '#fec200',
    shade4: '#fff9e9',
    shade5: '#FFF6DC',
    shade6: '#FFD258',
  },
  teal: {
    shade1: '#07757E',
    shade2: '#0B5D64',
  },
  lime: {
    'lime-01': '#f7fbe6',
    'lime-02': '#ebf5c2',
    'lime-03': '#dced91',
    'lime-04': '#cbe55e',
    'lime-05': '#bcd02e',
    'lime-06': '#add500',
    'lime-07': '#93b500',
    'lime-08': '#7b9700',
    'lime-09': '#637900',
    'lime-10': '#4e6000',
  },
  sky: {
    'sky-01': '#e6f1fa',
    'sky-02': '#c2dff3',
    'sky-03': '#91c2ea',
    'sky-04': '#5ea6e0',
    'sky-05': '#2e8bd7',
    'sky-06': '#0070ce',
    'sky-07': '#0061af',
    'sky-08': '#005192',
    'sky-09': '#004075',
    'sky-10': '#00335d',
  },
  mint: {
    'mint-01': '#fefff3',
    'mint-02': '#dafee3',
    'mint-03': '#b8fac9',
    'mint-04': '#80f5a0',
    'mint-05': '#41e770',
    'mint-06': '#19ce4d',
    'mint-07': '#0d9a37',
    'mint-08': '#116a2b',
    'mint-09': '#105726',
    'mint-10': '#033012',
  },
  magenta: {
    'magenta-01': '#fdf2fb',
    'magenta-02': '#fce7f8',
    'magenta-03': '#fad0f2',
    'magenta-04': '#fba8e9',
    'magenta-05': '#f274d5',
    'magenta-06': '#e4ac40',
    'magenta-07': '#d092a2',
    'magenta-08': '#ae1878',
    'magenta-09': '#8215d9',
    'magenta-10': '#4f0855',
  },
};

export const storeColors = {
  falabella: 'lime',
  tienda: 'lime',
  sodimac: 'sky',
  so_com: 'sky',
  tottus: 'mint',
  to_com: 'mint',
  linio: 'magenta',
};

export const getStoreShade = (store = 'falabella', shade = 1) => {
  const key = storeColors[store] || storeColors.falabella;
  return colors[key][`${key}-${String(shade).padStart(2, '0')}`];
};

export const renderColorsAsCssVariables = () => {
  let cssVariables = '';

  Object.keys(colors).forEach((color) => {
    if (typeof colors[color] === 'string') {
      cssVariables += `--${color}: ${colors[color]};`;
      return;
    }
    Object.keys(colors[color]).forEach((shade) => {
      const variableName = `--${color}-${shade}`;
      const variableValue = colors[color][shade];
      cssVariables += `${variableName}: ${variableValue};`;
    });
  });

  return `
    :root {
      ${cssVariables}
    }
  `;
};

export default colors;
